import React from "react";
import SEO from "../components/seo";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const FeaturedPost = ({ post }) => {
    return (
        <article
            className={"flex-1 border border-gray-300 rounded-xl m-8 mx-4 leading-relaxed shadow-md "}
            style={{ minWidth: 300, maxWidth: 400 }}
        >
            <Link to={`${post.path}`} className={"block pb-12 h-full relative"}>
                <div className={"p-4 w-full"}>
                    <GatsbyImage
                        image={post.cover_photo.childImageSharp.gatsbyImageData}
                        title={post.title}
                        alt={post.title} />
                </div>
                <h2 className={"text-xl px-8 mb-4 font-extrabold"}>{post.title}</h2>
                <p className={"px-8"}>{post.teaser}</p>
                <div className={"pr-4 text-gray-700 absolute text-sm"} style={{ bottom: 10, right: 0 }}>
                    {post.date}
                </div>
            </Link>
        </article>
    );
};

const SimplePost = ({ post, className }) => {
    return (
        <article className={"leading-relaxed p-8 mx-auto mb-8"} style={{ maxWidth: 800 }}>
            <Link to={`${post.path}`} className={"block"}>
                <h2 className={"text-center text-2xl font-extrabold"}>{post.title}</h2>
                <div className={"text-center text-gray-700 text-sm"}>{post.date}</div>
                <p className={"pt-4"}>{post.teaser}</p>
            </Link>
        </article>
    );
};

const SecondPage = ({location}) => {
    const data = useStaticQuery(graphql`query PostsQuery {
  allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}) {
    edges {
      node {
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
          teaser
          path
          featured
          cover_photo {
            relativePath
            name
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
}
`);
    const edges = data.allMarkdownRemark.edges;
    const posts = [];
    const featuredPosts = [];
    edges.forEach(edge => {
        const post = edge.node.frontmatter;
        posts.push(post);
        if (post.featured) {
            featuredPosts.push(post);
        }
    });

    return (
        <div className={"py-32"}>
            <SEO
                meta_title="Agile Blog by Scrum Mate"
                breadcrumb={"Blog"}
                description={"Articles, tips, and tricks about agile for product teams, software studios, and agile businesses."}
                location={location}
            />
            <section className={"container mx-auto text-center"}>
                <h1 className={"text-2xl xsm:text-7xl font-extrabold mb-8"}>Blog</h1>
                <h2 className={"p-4"}>News, tips and tricks about doing agile with Scrum Mate</h2>
            </section>
            {featuredPosts.length && (
                <section className={"pt-16"}>
                    <div className={"flex flex-wrap justify-center px-4"}>
                        {featuredPosts.map((post, i) => (
                            <FeaturedPost post={post} key={i} />
                        ))}
                    </div>
                </section>
            )}
            {posts.length && (
                <div className={"py-8 text-center mt-16"}>
                    <h2 className={"text-2xl xsm:text-5xl font-extrabold text-center px-8 mb-8"}>All posts</h2>
                    <section>
                        {posts.map((post, i) => (
                            <SimplePost post={post} key={i} />
                        ))}
                    </section>
                </div>
            )}
        </div>
    );
};

export default SecondPage;
